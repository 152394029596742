<template>
  <div class="reset-password">
    <b-alert
      v-if="registrationSuccessful"
      show
      variant="success"
    >
      You have successfully registered
      <router-link :to="{ name: 'Login' }">
        please login here
      </router-link>.
    </b-alert>
    <compliance-form-wrapper
      v-else
      id="registration-form"
      v-bind="{ formModel }"
      :field-errors.sync="fieldErrors"
      class="mb-3"
      :disabled="registrationSuccessful"
      :show-submit="false"
      @submit="doSubmit"
    />
    <vue-recaptcha
      v-if="isProduction"
      ref="recaptcha"
      :load-recaptcha-script="true"
      sitekey="6LcCrCgaAAAAAA0_SmhprN5_ijgUk6knPBJUWogg"
      size="invisible"
      @verify="doRegister"
      @error="unsetLoading('submitting')"
      @expired="onCaptchaExpired"
    />
    <b-btn
      variant="primary"
      :disabled="isLoading('submitting')"
      @click.prevent="$complianceForm.submit('registration-form')"
    >
      <b-spinner
        v-if="isLoading('submitting')"
        class="mr-2"
        small
      />
      Register
    </b-btn>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import formModel from '@/assets/forms/auth/register';
import recaptchaMixin from '@/mixins/recaptcha';

export default {
  mixins: [recaptchaMixin],
  data: () => ({
    formModel,
    fieldErrors: null,
    registrationSuccessful: false,
    registering: false,
    payload: null,
  }),
  methods: {
    ...mapActions('auth', ['register']),
    async doRegister() {
      const { payload } = this;
      if (payload) {
        this.registering = true;
        try {
          await this.register({
            data: payload,
            token: this.$route.params.token,
          });
          this.registrationSuccessful = true;
        } catch (e) {
          if (e.data) {
            this.fieldErrors = e.data.errors || null;
          }
        } finally {
          this.unsetLoading('submitting');
        }
      }
    },
    doSubmit(data) {
      this.setLoading('submitting');
      this.payload = data;
      if (this.isProduction) {
        this.$refs.recaptcha.execute();
      } else {
        this.doRegister();
      }
    },
  },
};
</script>
