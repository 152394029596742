<template>
  <div class="reset-password-request">
    <b-alert
      :show="requested"
      variant="success"
    >
      Please check your emails for instructions and a link to reset your password.
    </b-alert>
    <compliance-form-wrapper
      v-bind="{ formModel }"
      :disabled="requested"
      :show-submitting="isLoading('submitting')"
      class="mb-3"
      @submit="doSubmit"
    />
    <vue-recaptcha
      v-if="isProduction"
      ref="recaptcha"
      :load-recaptcha-script="true"
      sitekey="6LcCrCgaAAAAAA0_SmhprN5_ijgUk6knPBJUWogg"
      size="invisible"
      @verify="doRequestPasswordReset"
      @error="unsetLoading('submitting')"
      @expired="onCaptchaExpired"
    />
    <router-link :to="{ name: 'Login' }">
      &lt; Back to login
    </router-link>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import formModel from '@/assets/forms/auth/resetRequest';
import recaptchaMixin from '@/mixins/recaptcha';

export default {
  mixins: [recaptchaMixin],
  data: () => ({
    formModel,
    requested: false,
    payload: null,
  }),
  methods: {
    ...mapActions('auth', ['requestPasswordReset']),
    async doRequestPasswordReset() {
      await this.requestPasswordReset(this.payload);
      this.requested = true;
      this.unsetLoading('submitting');
    },
    doSubmit(data) {
      this.setLoading('submitting');
      this.payload = data;
      if (this.isProduction) {
        this.$refs.recaptcha.execute();
      } else {
        this.doRequestPasswordReset();
      }
    },
  },
};
</script>
