export default {
  fields: [
    {
      id: 'email',
      key: 'email',
      label: 'Email address',
      display_type: 'email',
      rules: ['required', 'email'],
    },
    {
      id: 'password',
      key: 'password',
      label: 'Password',
      display_type: 'password',
      rules: ['required'],
    },
    {
      id: 'password_confirmation',
      key: 'password_confirmation',
      label: 'Confirm password',
      display_type: 'password',
      rules: ['required', 'eq_f:password,false'],
    },
  ],
};
