var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reset-password" },
    [
      _vm.registrationSuccessful
        ? _c(
            "b-alert",
            { attrs: { show: "", variant: "success" } },
            [
              _vm._v(" You have successfully registered "),
              _c("router-link", { attrs: { to: { name: "Login" } } }, [
                _vm._v(" please login here ")
              ]),
              _vm._v(". ")
            ],
            1
          )
        : _c(
            "compliance-form-wrapper",
            _vm._b(
              {
                staticClass: "mb-3",
                attrs: {
                  id: "registration-form",
                  "field-errors": _vm.fieldErrors,
                  disabled: _vm.registrationSuccessful,
                  "show-submit": false
                },
                on: {
                  "update:fieldErrors": function($event) {
                    _vm.fieldErrors = $event
                  },
                  "update:field-errors": function($event) {
                    _vm.fieldErrors = $event
                  },
                  submit: _vm.doSubmit
                }
              },
              "compliance-form-wrapper",
              { formModel: _vm.formModel },
              false
            )
          ),
      _vm.isProduction
        ? _c("vue-recaptcha", {
            ref: "recaptcha",
            attrs: {
              "load-recaptcha-script": true,
              sitekey: "6LcCrCgaAAAAAA0_SmhprN5_ijgUk6knPBJUWogg",
              size: "invisible"
            },
            on: {
              verify: _vm.doRegister,
              error: function($event) {
                return _vm.unsetLoading("submitting")
              },
              expired: _vm.onCaptchaExpired
            }
          })
        : _vm._e(),
      _c(
        "b-btn",
        {
          attrs: { variant: "primary", disabled: _vm.isLoading("submitting") },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.$complianceForm.submit("registration-form")
            }
          }
        },
        [
          _vm.isLoading("submitting")
            ? _c("b-spinner", { staticClass: "mr-2", attrs: { small: "" } })
            : _vm._e(),
          _vm._v(" Register ")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }