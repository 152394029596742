var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reset-password-request" },
    [
      _c("b-alert", { attrs: { show: _vm.requested, variant: "success" } }, [
        _vm._v(
          " Please check your emails for instructions and a link to reset your password. "
        )
      ]),
      _c(
        "compliance-form-wrapper",
        _vm._b(
          {
            staticClass: "mb-3",
            attrs: {
              disabled: _vm.requested,
              "show-submitting": _vm.isLoading("submitting")
            },
            on: { submit: _vm.doSubmit }
          },
          "compliance-form-wrapper",
          { formModel: _vm.formModel },
          false
        )
      ),
      _vm.isProduction
        ? _c("vue-recaptcha", {
            ref: "recaptcha",
            attrs: {
              "load-recaptcha-script": true,
              sitekey: "6LcCrCgaAAAAAA0_SmhprN5_ijgUk6knPBJUWogg",
              size: "invisible"
            },
            on: {
              verify: _vm.doRequestPasswordReset,
              error: function($event) {
                return _vm.unsetLoading("submitting")
              },
              expired: _vm.onCaptchaExpired
            }
          })
        : _vm._e(),
      _c("router-link", { attrs: { to: { name: "Login" } } }, [
        _vm._v(" < Back to login ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }