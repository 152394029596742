export default {
  fields: [
    {
      id: 'email',
      key: 'email',
      label: 'Email address',
      display_type: 'email',
      rules: ['required', 'email'],
      help_text: 'Passwords should contain: a minimum of 8 characters, at least 1 number, at least 1 lowercase letter, at least 1 uppercase letter, no more than 2 identical characters in a row.',
    },
  ],
};
