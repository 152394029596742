import VueRecaptcha from 'vue-recaptcha';
import { loadingMixin } from '@itccompliance/compliance-vue-essentials-plugin';

export default {
  mixins: [loadingMixin],
  components: { VueRecaptcha },
  computed: {
    isProduction() {
      return process.env.NODE_ENV === 'production';
    },
  },
  methods: {
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
  },
};
