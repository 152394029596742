<template>
  <div class="reset-password">
    <b-alert
      v-if="resetSuccessful"
      show
      variant="success"
    >
      Your password has been successfully reset,
      <router-link :to="{ name: 'Login' }">
        please login here
      </router-link>.
    </b-alert>
    <compliance-form-wrapper
      v-else
      v-bind="{ formModel }"
      :show-submitting="isLoading('submitting')"
      :field-errors.sync="fieldErrors"
      class="mb-3"
      @submit="doSubmit"
    />
    <vue-recaptcha
      v-if="isProduction"
      ref="recaptcha"
      :load-recaptcha-script="true"
      sitekey="6LcCrCgaAAAAAA0_SmhprN5_ijgUk6knPBJUWogg"
      size="invisible"
      @verify="doResetPassword"
      @error="unsetLoading('submitting')"
      @expired="onCaptchaExpired"
    />
    <router-link :to="{ name: 'Login' }">
      &lt; Back to login
    </router-link>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import resetForm from '@/assets/forms/auth/reset';
import recaptchaMixin from '@/mixins/recaptcha';

export default {
  mixins: [recaptchaMixin],
  data: () => ({
    resetSuccessful: false,
    fieldErrors: null,
  }),
  computed: {
    formModel() {
      const { fields } = resetForm;
      return {
        fields: [
          ...fields,
          {
            id: 'token',
            display_type: 'hidden',
            value: this.$route.params.token,
          },
        ],
      };
    },
  },
  methods: {
    ...mapActions('auth', ['resetPassword']),
    async doResetPassword() {
      try {
        await this.resetPassword(this.payload);
        this.resetSuccessful = true;
      } catch (e) {
        if (e.data) {
          this.fieldErrors = e.data.errors || null;
        }
      }
      this.unsetLoading('submitting');
    },
    doSubmit(data) {
      this.setLoading('submitting');
      this.payload = data;
      if (this.isProduction) {
        this.$refs.recaptcha.execute();
      } else {
        this.doResetPassword();
      }
    },
  },
};
</script>
