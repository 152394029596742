<template>
  <div class="login">
    <compliance-form-wrapper
      v-bind="{ formModel, changeHandlers }"
      :field-errors.sync="fieldErrors"
      :show-submitting="isLoading('submitting')"
      class="mb-3"
      @submit="doSubmit"
    />
    <vue-recaptcha
      v-if="isProduction"
      ref="recaptcha"
      :load-recaptcha-script="true"
      sitekey="6LcCrCgaAAAAAA0_SmhprN5_ijgUk6knPBJUWogg"
      size="invisible"
      @verify="doLogin"
      @error="unsetLoading('submitting')"
      @expired="onCaptchaExpired"
    />
    <router-link :to="{ name: 'Request Password' }">
      Forgotten password?
    </router-link>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import formModel from '@/assets/forms/auth/login';
import recaptchaMixin from '@/mixins/recaptcha';

export default {
  mixins: [recaptchaMixin],
  data: (vm) => ({
    formModel,
    fieldErrors: null,
    changeHandlers: {
      resetValidation: () => {
        vm.$set(vm, 'fieldErrors', null);
      },
    },
    payload: null,
  }),
  methods: {
    ...mapActions('auth', ['login']),
    async doLogin() {
      try {
        await this.login(this.payload);
        this.$router.replace({ name: 'Home' });
      } catch (e) {
        this.fieldErrors = {
          email: [e.data.details],
        };
      } finally {
        this.unsetLoading('submitting');
      }
    },
    doSubmit(data) {
      this.setLoading('submitting');
      this.payload = data;
      if (this.isProduction) {
        this.$refs.recaptcha.execute();
      } else {
        this.doLogin();
      }
    },
  },
};
</script>
