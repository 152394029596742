export default {
  fields: [
    {
      id: 'forename',
      key: 'forename',
      label: 'Forename',
      display_type: 'text',
      rules: ['required'],
    },
    {
      id: 'surname',
      key: 'surname',
      label: 'Surname',
      display_type: 'text',
      rules: ['required'],
    },
    {
      id: 'password',
      key: 'password',
      label: 'Password',
      display_type: 'password',
      rules: ['required'],
      help_text: 'Passwords should contain a minimum of 8 characters, at least 1 number, at least 1 lowercase letter, at least 1 uppercase letter and no more than 2 identical characters in a row.',
    },
    {
      id: 'password_confirmation',
      key: 'password_confirmation',
      label: 'Confirm password',
      display_type: 'password',
      rules: ['required', 'eq_f:password,false'],
    },
  ],
};
