var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reset-password" },
    [
      _vm.resetSuccessful
        ? _c(
            "b-alert",
            { attrs: { show: "", variant: "success" } },
            [
              _vm._v(" Your password has been successfully reset, "),
              _c("router-link", { attrs: { to: { name: "Login" } } }, [
                _vm._v(" please login here ")
              ]),
              _vm._v(". ")
            ],
            1
          )
        : _c(
            "compliance-form-wrapper",
            _vm._b(
              {
                staticClass: "mb-3",
                attrs: {
                  "show-submitting": _vm.isLoading("submitting"),
                  "field-errors": _vm.fieldErrors
                },
                on: {
                  "update:fieldErrors": function($event) {
                    _vm.fieldErrors = $event
                  },
                  "update:field-errors": function($event) {
                    _vm.fieldErrors = $event
                  },
                  submit: _vm.doSubmit
                }
              },
              "compliance-form-wrapper",
              { formModel: _vm.formModel },
              false
            )
          ),
      _vm.isProduction
        ? _c("vue-recaptcha", {
            ref: "recaptcha",
            attrs: {
              "load-recaptcha-script": true,
              sitekey: "6LcCrCgaAAAAAA0_SmhprN5_ijgUk6knPBJUWogg",
              size: "invisible"
            },
            on: {
              verify: _vm.doResetPassword,
              error: function($event) {
                return _vm.unsetLoading("submitting")
              },
              expired: _vm.onCaptchaExpired
            }
          })
        : _vm._e(),
      _c("router-link", { attrs: { to: { name: "Login" } } }, [
        _vm._v(" < Back to login ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }